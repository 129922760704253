import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Input for a network interface of an ECDN server.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> InterfaceInput <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Name of network interface (ie. eth0, eth1, ens36, etc...).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Currently it must be eth0 (multiple interfaces aren't supported yet).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">name</GQLName>: <GQLLink href="/ecdn-api-scalars/String" mdxType="GQLLink">String</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Configuration mode of interface's network address.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">networkConfigurationMode</GQLName>: <GQLLink href="/ecdn-api-enums/NetworkConfigurationMode" mdxType="GQLLink">NetworkConfigurationMode</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Local IP of interface.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Omit if network configuration mode is DYNAMIC.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Required if network configuration mode is STATIC.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">localIP</GQLName>: <GQLLink href="/ecdn-api-scalars/IPv4" mdxType="GQLLink">IPv4</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Netmask of interface's local network</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Omit if network configuration mode is DYNAMIC.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Required if network configuration mode is STATIC.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">netmask</GQLName>: <GQLLink href="/ecdn-api-scalars/IPv4Mask" mdxType="GQLLink">IPv4Mask</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Gateway of interface's local network.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Omit if network configuration mode is DYNAMIC.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Required if network configuration mode is STATIC.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">gateway</GQLName>: <GQLLink href="/ecdn-api-scalars/IPv4" mdxType="GQLLink">IPv4</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`name: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/String"
      }}>{`String`}</a>{`!`}</h3>
    <p>{`Name of network interface (ie. eth0, eth1, ens36, etc…).
Currently it must be eth0 (multiple interfaces aren’t supported yet).`}</p>
    <h3>{`networkConfigurationMode: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/NetworkConfigurationMode"
      }}>{`NetworkConfigurationMode`}</a>{`!`}</h3>
    <p>{`Configuration mode of interface’s network address.`}</p>
    <h3>{`localIP: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/IPv4"
      }}>{`IPv4`}</a></h3>
    <p>{`Local IP of interface.
Omit if network configuration mode is DYNAMIC.
Required if network configuration mode is STATIC.`}</p>
    <h3>{`netmask: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/IPv4Mask"
      }}>{`IPv4Mask`}</a></h3>
    <p>{`Netmask of interface’s local network
Omit if network configuration mode is DYNAMIC.
Required if network configuration mode is STATIC.`}</p>
    <h3>{`gateway: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/IPv4"
      }}>{`IPv4`}</a></h3>
    <p>{`Gateway of interface’s local network.
Omit if network configuration mode is DYNAMIC.
Required if network configuration mode is STATIC.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      